import React, { useState, useEffect } from "react";
import useCustomMutation from "../../../../lib/Hooks/useCustomMutation";
import { Row, Col } from "reactstrap";
import LogoEdit from "../../../Common/Profile/LogoEdit";
import Select from "react-select";
import { sectorList } from "../../../../lib/utils/Sectors";
import { countryList } from "../../../../lib/utils/Countries";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ButtonSubmit from "../../../Common/Buttons/ButtonSubmit";

const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#33A6CD" : "white",
    color: state.isFocused ? "white" : "black",
  }),
  control: (provided) => ({
    ...provided,
    borderColor: "#ccc",
    "&:hover": {
      borderColor: "#33A6CD",
    },
  }),
};

const EditCompany = ({ organizationID, organizationDetails, token }) => {
  const [showImage, setShowImage] = useState(null);
  const [uploadImg, setUploadImg] = useState(null);
  const [inputs, setInputs] = useState({
    name: "",
    sector: "",
    registrationNumber: "",
    vatNumber: "",
    email: "",
    phoneNumber: "",
    country: "",
    size: "",
  });

  const key = ["CompanyDetails", organizationID];
  const mutation = useCustomMutation(key);

  // Handle field changes
  const handleChange = (field, value) => {
    setInputs((prevInputs) => ({ ...prevInputs, [field]: value }));
  };

  // Submit form data
  const toggleSaveChanges = (e) => {
    e.preventDefault();

    const url = `organizations/${organizationID}`;
    const formData = new FormData();

    if (uploadImg) {
      formData.append("bannerImage", uploadImg);
    }

    Object.entries(inputs).forEach(([key, value]) => {
      if (value) {
        formData.append(key, value);
      }
    });

    mutation.mutate({
      endPoint: url,
      bodyContent: formData,
      Method: "PATCH",
      displayMessage: "Company details successfully updated",
      token,
      isJSON: false,
    });
  };

  // Set initial form values from organization details
  useEffect(() => {
    if (organizationDetails?.data) {
      const {
        bannerImage,
        name,
        size,
        sector,
        email,
        phoneNumber,
        registrationNumber,
        vatNumber,
        country,
      } = organizationDetails.data;

      setShowImage(bannerImage);
      setInputs({
        name: name || "",
        size: size || "",
        sector: sector || "",
        email: email || "",
        phoneNumber: phoneNumber || "",
        registrationNumber: registrationNumber || "",
        vatNumber: vatNumber || "",
        country: country || "",
      });
    }
  }, [organizationDetails]);

  return (
    <form
      noValidate
      autoComplete="off"
      data-testid="form"
      onSubmit={toggleSaveChanges}
    >
      <div className="d-flex justify-content-center">
        <Row style={{ width: "90%" }}>
          <Col md={12}>
            <div className="w-100 mb-2 d-flex justify-content-center">
              <h4>Company Details</h4>
            </div>
            <div className="w-100 d-flex justify-content-center">
              <LogoEdit
                showImage={showImage}
                setShowImage={setShowImage}
                setUploadImg={setUploadImg}
              />
            </div>
            <div className="w-100 mt-2 mb-5 d-flex justify-content-center">
              logo size (400 X 200) pixels
            </div>
          </Col>

          {/* Company Name */}
          <Col md={6}>
            <label>
              Company Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={inputs.name}
              placeholder="Enter Company Name"
              onChange={(e) => handleChange("name", e.target.value)}
              className="mb-3 form-control fromInput"
              style={{ borderRadius: 7 }}
              required
            />
          </Col>

          {/* Company Size */}
          <Col md={6}>
            <label>
              Company Size <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              name="size"
              value={inputs.size}
              placeholder="Enter Company Size"
              onChange={(e) => handleChange("size", e.target.value)}
              className="mb-3 form-control fromInput"
              style={{ borderRadius: 7 }}
              required
            />
          </Col>

          {/* Sector */}
          <Col md={6}>
            <label>
              Sector <span className="text-danger">*</span>
            </label>
            <Select
              options={sectorList.map((sector) => ({
                value: sector,
                label: sector,
              }))}
              value={
                inputs.sector
                  ? { value: inputs.sector, label: inputs.sector }
                  : null
              }
              styles={customSelectStyles}
              placeholder="Select Sector"
              onChange={(option) => handleChange("sector", option.value)}
            />
          </Col>

          {/* Email */}
          <Col md={6}>
            <label>
              Company Email <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={inputs.email}
              placeholder="Enter Company Email"
              onChange={(e) => handleChange("email", e.target.value)}
              className="mb-3 form-control fromInput"
              style={{ borderRadius: 7 }}
              required
            />
          </Col>

          {/* Phone Number */}
          <Col md={6}>
            <label>
              Phone Number <span className="text-danger">*</span>
            </label>
            <div className="phone-input-container">
              <PhoneInput
                international
                countryCallingCodeEditable={false}
                defaultCountry="ZA"
                value={inputs.phoneNumber || ""}
                onChange={(value) => handleChange("phoneNumber", value)}
                className="custom-phone-input"
              />
            </div>
          </Col>

          {/* Registration Number */}
          <Col md={6}>
            <label>
              Registration Number <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="registrationNumber"
              value={inputs.registrationNumber}
              placeholder="Enter Registration Number"
              onChange={(e) =>
                handleChange("registrationNumber", e.target.value)
              }
              className="mb-3 form-control fromInput"
              style={{ borderRadius: 7 }}
              required
            />
          </Col>

          {/* VAT Number */}
          <Col md={6}>
            <label>
              VAT Number <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="vatNumber"
              value={inputs.vatNumber}
              placeholder="Enter VAT Number"
              onChange={(e) => handleChange("vatNumber", e.target.value)}
              className="mb-3 form-control fromInput"
              style={{ borderRadius: 7 }}
              required
            />
          </Col>

          {/* Country */}
          <Col md={6}>
            <label>
              Country <span className="text-danger">*</span>
            </label>
            <Select
              options={countryList.map((country) => ({
                value: country,
                label: country,
              }))}
              value={
                inputs.country
                  ? { value: inputs.country, label: inputs.country }
                  : null
              }
              styles={customSelectStyles}
              placeholder="Select Country"
              onChange={(option) => handleChange("country", option.value)}
            />
          </Col>

          {/* Submit Button */}
          <div className="w-100 d-flex justify-content-center mt-4">
            <ButtonSubmit
              Title="Update"
              ColorText="white"
              BorderColor="#33A6CD"
              BackgroundColor="#33A6CD"
              borderRadius="25px"
              handleOnclick={toggleSaveChanges}
              pending={mutation?.isLoading}
            />
          </div>
        </Row>
      </div>
    </form>
  );
};

export default EditCompany;
