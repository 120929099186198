import React from "react";
import useCustomFetchQuery from "../../../lib/Hooks/useCustomFecthQuery";
import CardItem from "../../../components/Pages/Dashboard/Admin/CardItem";
import MonthlyAnalysis from "../../../components/Pages/Dashboard/Admin/MonthlyAnalysis";
import EmployeeTable from "../../../components/Pages/Dashboard/Admin/EmployeeTable";

const Admin = ({ Navigate, token, organizationID }) => {
  const {
    data: orgAnalytics,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["OrgAnalytics", organizationID],
    `${process.env.REACT_APP_APIKEY}reports/${organizationID}`,
    token,
    { enabled: !!organizationID }
  );

  const {
    data: getEmployees,
    error: errorEmployees,
    isLoading: isLoadingEmplolyees,
  } = useCustomFetchQuery(
    ["AllEmployees", organizationID],
    `${process.env.REACT_APP_APIKEY}employees?oid=${organizationID}`,
    token,
    { enabled: !!organizationID }
  );
  // Filtering and sorting the data array
  const filteredEmployeesData =
    getEmployees?.data
      .filter((employee) =>
        employee?.roles?.some((role) =>
          ["Learner", "Intern", "Employee"].includes(role?.type)
        )
      )
      .sort((a, b) => a?.firstName?.localeCompare(b?.firstName)) || [];

  return (
    <React.Fragment>
      <CardItem orgAnalytics={orgAnalytics} />
      <EmployeeTable
        Navigate={Navigate}
        filteredEmployeesData={filteredEmployeesData}
      />
      <MonthlyAnalysis orgAnalytics={orgAnalytics} />
    </React.Fragment>
  );
};

export default Admin;
