import React, { useState, useMemo, useEffect } from "react";
import SimpleBar from "simplebar-react";
import { Row, Col } from "reactstrap";
import useCustomMutation from "../../../../lib/Hooks/useCustomMutation";
import "./completeReview.css";
import { warningMessage } from "../../../Common/Toast/Toast";
import submitReview from "../../../../lib/utils/submitReview";
import CompleteSummary from "./CompleteSummary";
import GuideLine from "./GuideLine";
import { Slider, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  slider: {
    width: "100%",
    color: "#33a6cd",
  },
});

const SliderQuestion = ({ questionText, value, onChange, min, max }) => {
  const classes = useStyles();

  // Generate marks dynamically based on the range
  const marks = Array.from({ length: max - min + 1 }, (_, i) => ({
    value: min + i,
    label: (min + i).toString(),
  }));

  return (
    <div className="question">
      <Typography gutterBottom>{questionText}</Typography>
      <Slider
        value={value}
        onChange={onChange}
        aria-labelledby="slider-question"
        step={1}
        min={min}
        max={max}
        marks={marks} // Dynamic marks
        valueLabelDisplay="auto"
        className={classes.slider}
      />
    </div>
  );
};

const SliderSection = ({
  sectionTitle,
  sectionMarks,
  sectionKey,
  questions,
  ranges,
  answers,
  onChange,
}) => {
  return (
    <div className="section">
      <h3>
        {sectionTitle} {sectionMarks}
      </h3>
      {questions.map((question, index) => (
        <SliderQuestion
          key={index}
          questionText={question}
          value={answers[sectionKey][index] || ranges[index].min}
          min={ranges[index].min}
          max={ranges[index].max}
          onChange={(e, value) => onChange(sectionKey, index, value)}
        />
      ))}
    </div>
  );
};

const OverallProgress = ({ answers, handleSelect }) => {
  return (
    <div className="section">
      <h3>Overall Progress</h3>

      <div className="question">
        <p>
          Employee performance and learning is unsatisfactory and is failing to
          improve at a satisfactory rate
        </p>
        <div className="choices gap-3">
          <button
            className={`choice-button ${
              answers.overallProgress.performanceFailing === "Yes"
                ? "active"
                : ""
            }`}
            onClick={() =>
              handleSelect("overallProgress", "performanceFailing", "Yes")
            }
          >
            Yes
          </button>
          <button
            className={`choice-button ${
              answers.overallProgress.performanceFailing === "No"
                ? "active"
                : ""
            }`}
            onClick={() =>
              handleSelect("overallProgress", "performanceFailing", "No")
            }
          >
            No
          </button>
        </div>
      </div>

      <div className="question">
        <p>
          Employee performance and learning is acceptable and is improving at a
          satisfactory rate
        </p>
        <div className="choices gap-3">
          <button
            className={`choice-button ${
              answers.overallProgress.performanceAcceptable === "Yes"
                ? "active"
                : ""
            }`}
            onClick={() =>
              handleSelect("overallProgress", "performanceAcceptable", "Yes")
            }
          >
            Yes
          </button>
          <button
            className={`choice-button ${
              answers.overallProgress.performanceAcceptable === "No"
                ? "active"
                : ""
            }`}
            onClick={() =>
              handleSelect("overallProgress", "performanceAcceptable", "No")
            }
          >
            No
          </button>
        </div>
      </div>

      <div className="question">
        <p>
          Employee has successfully demonstrated outstanding overall performance
        </p>
        <div className="choices gap-3">
          <button
            className={`choice-button ${
              answers.overallProgress.performanceOutstanding === "Yes"
                ? "active"
                : ""
            }`}
            onClick={() =>
              handleSelect("overallProgress", "performanceOutstanding", "Yes")
            }
          >
            Yes
          </button>
          <button
            className={`choice-button ${
              answers.overallProgress.performanceOutstanding === "No"
                ? "active"
                : ""
            }`}
            onClick={() =>
              handleSelect("overallProgress", "performanceOutstanding", "No")
            }
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

const CommentsSection = ({ answers, handleInputChange }) => {
  return (
    <div className="section">
      <h3>Comments</h3>

      <div className="question">
        <div>
          <label>Write your comment below</label>
        </div>
        <textarea
          value={answers.overallProgress.comment}
          onChange={(e) =>
            handleInputChange("overallProgress", "comment", e.target.value)
          }
          className="w-100 p-2 rounded-3"
          rows="4"
        />
      </div>
    </div>
  );
};

const CompleteReview = ({
  setIsCompleteEvatuation,
  token,
  userID,
  organizationID,
  departmentID,
}) => {
  const [answers, setAnswers] = useState({
    qualityOfWork: Array(3).fill(1),
    workHabits: Array(4).fill(1),
    jobKnowledge: Array(3).fill(1),
    interpersonalSkills: Array(5).fill(1),
    leadershipSkills: Array(3).fill(1),
    overallProgress: {
      performanceFailing: null,
      performanceAcceptable: null,
      performanceOutstanding: null,
      comment: "",
      recommendation: "",
    },
  });

  const key = ["MyReviews", organizationID];
  const Mutation = useCustomMutation(key);
  const [currentStep, setCurrentStep] = useState(1);

  const totalScore = useMemo(() => {
    let sum = 0;
    Object.keys(answers).forEach((section) => {
      if (Array.isArray(answers[section])) {
        sum += answers[section].reduce((acc, val) => acc + (val || 0), 0);
      }
    });
    return sum;
  }, [answers]);

  useEffect(() => {
    if (Mutation?.isSuccess) {
      setAnswers({
        qualityOfWork: Array(3).fill(1),
        workHabits: Array(4).fill(1),
        jobKnowledge: Array(3).fill(1),
        interpersonalSkills: Array(5).fill(1),
        leadershipSkills: Array(3).fill(1),
        overallProgress: {
          performanceFailing: null,
          performanceAcceptable: null,
          performanceOutstanding: null,
          comment: "",
          recommendation: "",
        },
      });
      setIsCompleteEvatuation(false);
    }
  }, [Mutation?.isSuccess]);

  const handleSliderChange = (sectionKey, index, value) => {
    setAnswers((prev) => {
      const updatedSection = [...prev[sectionKey]];
      updatedSection[index] = value;
      return { ...prev, [sectionKey]: updatedSection };
    });
  };

  const handleInputChange = (sectionKey, inputField, value) => {
    setAnswers((prev) => ({
      ...prev,
      [sectionKey]: {
        ...prev[sectionKey],
        [inputField]: value,
      },
    }));
  };

  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);

  const handleSubmit = (e) => {
    e.preventDefault();
    const outputData = submitReview(answers);
    const url = `assessments?uid=${userID}&oid=${organizationID}&did=${departmentID}`;
    const data = {
      endPoint: url,
      bodyContent: JSON.stringify({ assessment: { employee: outputData } }),
      Method: "POST",
      displayMessage: "Review successfully submitted",
      token,
    };
    Mutation.mutate(data);
  };

  // console.log("answers....", JSON.stringify(answers, null, 2));
  return (
    <div>
      <Row>
        <Col md={12} lg={8}>
          <div className="evaluation-form">
            {/* Step 1: Quality of Work */}
            {currentStep === 1 && (
              <SliderSection
                sectionTitle="Quality of Work"
                sectionMarks="(Out of 15 Marks)"
                sectionKey="qualityOfWork"
                questions={[
                  "Accuracy, neatness and timeliness of work",
                  "Adherence to duties and procedures in Job Descriptions and Work Instructions",
                  "Synchronization with organizational/functional goals",
                ]}
                ranges={[
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                ]}
                answers={answers}
                onChange={handleSliderChange}
              />
            )}

            {/* Step 2: Work Habits */}
            {currentStep === 2 && (
              <SliderSection
                sectionTitle="Work Habits"
                sectionMarks="(Out of 20 Marks)"
                sectionKey="workHabits"
                questions={[
                  "Punctuality to workplace",
                  "Attendance",
                  "Does the employee stay busy, look for things to do, initiates at workplace",
                  "Submits reports on time and meets deadlines",
                ]}
                ranges={[
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                ]}
                answers={answers}
                onChange={handleSliderChange}
              />
            )}

            {/* Step 3: Job Knowledge */}
            {currentStep === 3 && (
              <SliderSection
                sectionTitle="Job Knowledge"
                sectionMarks="(Out of 15 Marks)"
                sectionKey="jobKnowledge"
                questions={[
                  "Skill and ability to perform job satisfactorily",
                  "Shown interest in learning and improving",
                  "Problem-solving ability",
                ]}
                ranges={[
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                ]}
                answers={answers}
                onChange={handleSliderChange}
              />
            )}

            {/* Step 4: Interpersonal Skills */}
            {currentStep === 4 && (
              <SliderSection
                sectionTitle="Interpersonal Skills"
                sectionMarks="(Out of 25 Marks)"
                sectionKey="interpersonalSkills"
                questions={[
                  "Responds and contributes to team efforts",
                  "Responds positively to suggestions and instructions",
                  "Keeps supervisor informed of all details",
                  "Adapts well to changing circumstances",
                  "Seeks feedback to improve",
                ]}
                ranges={[
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                  { min: 1, max: 5 },
                ]}
                answers={answers}
                onChange={handleSliderChange}
              />
            )}

            {/* Step 5: Leadership Skills */}
            {currentStep === 5 && (
              <SliderSection
                sectionTitle="Leadership Skills"
                sectionMarks="(Out of 25 Marks)"
                sectionKey="leadershipSkills"
                questions={[
                  "Aspirant to climb up the ladder, accepts challenges, new responsibilities and roles",
                  "Innovative thinking - contribution to organizations and functions and personal growth",
                  "Work motivation",
                ]}
                ranges={[
                  { min: 1, max: 10 },
                  { min: 1, max: 10 },
                  { min: 1, max: 5 },
                ]}
                answers={answers}
                onChange={handleSliderChange}
              />
            )}

            {currentStep === 6 && (
              <OverallProgress
                answers={answers}
                handleSelect={(sectionKey, questionKey, value) =>
                  handleInputChange(sectionKey, questionKey, value)
                }
              />
            )}
            {currentStep === 7 && (
              <CommentsSection
                answers={answers}
                handleInputChange={handleInputChange}
              />
            )}

            <div className="form-navigation">
              {currentStep > 1 && (
                <button className="back-button" onClick={prevStep}>
                  Back
                </button>
              )}
              {currentStep < 7 && (
                <button className="next-button" onClick={nextStep}>
                  Next
                </button>
              )}
              {currentStep === 7 && (
                <button
                  className="submit-button"
                  onClick={handleSubmit}
                  disabled={Mutation?.isLoading}
                >
                  {Mutation?.isLoading ? "Submitting..." : "Submit"}
                </button>
              )}
            </div>
          </div>
        </Col>
        <Col md={12} lg={4}>
          <SimpleBar style={{ height: "500px" }}>
            <CompleteSummary answers={answers} totalScore={totalScore} />
            <GuideLine />
          </SimpleBar>
        </Col>
      </Row>
    </div>
  );
};

export default CompleteReview;
