export const Styles = {
  container: {
    maxWidth: "800px",
    padding: "24px",
  },
  title: {
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: "24px",
  },
  plansContainer: {
    display: "flex",
    gap: "16px",
    marginBottom: "24px",
  },
  planCard: {
    flex: "1",
    padding: "16px",
    borderRadius: "8px",
    border: "1px solid #33a6cd",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: "160px",
    userSelect: "none",
  },
  selectedPlan: {
    border: "1px solid #33a6cd",
  },
  cardIcon: {
    position: "absolute",
    top: "-8px",
    right: "-8px",
    backgroundColor: "#33a6cd",
    borderRadius: "50%",
    padding: "2px",
    color: "white",
    width: "25px",
    height: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contactUsPlan: {
    background: `linear-gradient(to right, #33a6cd, #6FBFE0)`,
    color: "white",
    border: "none",
  },
  planHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
  },
  planName: {
    fontWeight: "500",
  },
  price: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
  },
  priceAmount: {
    fontSize: "18px",
    fontWeight: "600",
  },
  priceInterval: {
    fontSize: "14px",
    color: "#6b7280",
  },
  activationNotice: {
    fontSize: "14px",
    color: "#6b7280",
    marginBottom: "16px",
  },
  whiteText: {
    color: "white",
  },
  button: {
    padding: "8px 16px",
    borderRadius: "6px",
    fontSize: "14px",
    fontWeight: "500",
    cursor: "pointer",
    border: "none",
    background: "none",
  },
  cancelButton: {
    width: "180px",
    height: "30px",
    color: "#ff0000",
    backgroundColor: "#fff",
    textAlign: "center",
    padding: "0px 0px 0px 4px",
    borderStyle: "solid",
    border: "2px solid #ff0000",
    borderRadius: 7,
  },
  upgradeButton: {
    width: "180px",
    height: "30px",
    backgroundColor: "white",
    color: "#33a6cd",
    marginRight: "12px",
    borderRadius: 5,
    borderStyle: "none",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
  },
  toggleDescription: {
    fontSize: "14px",
    color: "#6b7280",
    marginTop: "8px",
  },
};
