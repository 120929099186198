import React from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import logo from "../../assets/images/kago-hc-header.png";

// import SubscriptionForm from "../../components/Pages/Checkout/SubscriptionForm";
import SubsriptionDetails from "../../components/Pages/Checkout/SubsriptionDetails";
import "../Pricing/pricingStyle.scss";

import ButtonGradient from "../../components/Common/Buttons/ButtonGradient";

const Checkout = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userInfo = queryParams.get("data")
    ? JSON.parse(queryParams.get("data"))
    : {};
  // const subscription = JSON.parse(localStorage.getItem("subscriptionHC"));
  const currentYear = new Date().getFullYear();

  // const queryParams = new URLSearchParams(window.location.search);
  // const encryptedData = queryParams.get("data");

  console.log(
    "encryptedData...from ...user",
    JSON.stringify(userInfo, null, 2)
  );

  // console.log("subscription...", JSON.stringify(subscription, null, 2));

  return (
    <React.Fragment>
      <div className="pricing-page">
        <nav className="navbar-pricing">
          <div className="logo-pricing">
            <img src={logo} className="w-100 h-100" />
          </div>
          <ul className="nav-links-pricing"></ul>
        </nav>

        <div className="w-100" style={{ height: "83vh" }}>
          <div className="w-100 mt-2 d-flex justify-content-center">
            {/* <Row>
              <Col md={8}>
                <div
                  className="w-100 py-3 rounded-3"
                  style={{ backgroundColor: "white" }}
                >
                  <div className="px-4 py-2 h4">Checkout</div>
                  <div className="px-4 ">
                    <SubscriptionForm subscription={subscription} />
                  </div>
                </div>
              </Col>
              <Col md={4}>
                <SubsriptionDetails subscription={subscription} />
              </Col>
            </Row> */}
            <Row className="justify-content-center">
              <Col md={10} lg={8}>
                <Card className="shadow">
                  <Row className="g-0 ">
                    {/* Left side */}
                    <Col md={4} className="bg-primary text-white">
                      <CardBody>
                        <h5 className="fw-bold mb-4">Payment for:</h5>
                        <div className="mb-4">
                          <small>Early Board Special</small>
                          <p className="fw-bold">NZD $197</p>
                        </div>
                        <div className="mb-4">
                          <small>Regular Price</small>
                          <p className="fw-bold">NZD $257</p>
                        </div>
                        <div>
                          <small>Payment Plan</small>
                          <p className="fw-bold">4 x $75</p>
                        </div>
                      </CardBody>
                    </Col>

                    {/* Right side */}
                    <Col md={8}>
                      <CardBody>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          <img
                            src={logo}
                            alt="PayPal logo"
                            style={{ height: "40px" }}
                          />
                          {/* <small className="text-muted">Feb 26, 2017</small> */}
                        </div>
                        <h3 className="fw-bold mb-3">
                          Life Coaching Online Course
                        </h3>
                        <p className="text-muted mb-1">Regular price</p>
                        <h4 className="fw-bold mb-4">Total NZD $257</h4>
                        <p className="mb-1">Hello David,</p>
                        <p className="mb-3">
                          You are confirming the payment of NZD $257 to Life
                          Coaching Online Course.
                        </p>
                        <p className="text-muted small mb-4">
                          It may take a few moments for this transaction to
                          appear in your account.
                        </p>
                        <div className="w-100 d-flex justify-content-end p-2">
                          <ButtonGradient
                            Title="Pay Now"
                            leftColor="#269CC3"
                            rightColor="#33a6cd"
                            ColorText="#ffff"
                            BorderColor="#33a6cd"
                            borderRadius={20}
                            handleOnclick={() => {}}
                          />
                        </div>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            {/* <div
              className="w-50 py-3 rounded-3"
              style={{ backgroundColor: "white" }}
            >
              <SubsriptionDetails subscription={subscription} />
            </div> */}
          </div>
        </div>

        <div className="pricing-footer">
          <div className="footer-content">
            <div>© {currentYear} HC Kago. All rights reserved.</div>
            <div>
              <a href="#" className="footer-link">
                Privacy Policy
              </a>{" "}
              |
              <a href="#" className="footer-link">
                Terms of Service
              </a>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Checkout;
