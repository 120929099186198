/* eslint-disable no-unused-expressions */

import {
  dangerMessage,
  successMessage,
  warningMessage,
} from "../../components/Common/Toast/Toast";

async function Post(
  endPoint,
  bodyContent,
  Method,
  displayMessage,
  token,
  isJSON
) {
  const myHeaders = new Headers();
  myHeaders.append("Authorization", `Bearer ${token}`);
  // Append Content-Type only for JSON payloads
  if (isJSON) {
    myHeaders.append("Content-Type", "application/json");
  }
  // isJSON ? null : myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: Method,
    headers: myHeaders,
    body: bodyContent,
    redirect: "follow",
  };

  // ${process.env.REACT_APP_APIKEY}

  const result = await fetch(
    `https://employee-evaluation-kago-e63baae4d822.herokuapp.com/api/v1/${endPoint}`,
    requestOptions
  )
    .then((response) => response.json())
    .then((res) => {
      if (res?.status === "success") {
        displayMessage === null ? null : successMessage(`${displayMessage}`);
        return res;
      }
      if (res?.status === "fail") {
        const error = res?.message ? res?.message : res?.data;
        warningMessage(`${error} `);
        return error;
      }
      if (res?.status === "error") {
        const error = res?.message ? res?.message : res?.data;
        dangerMessage(`${error}`);
        if (res?.message === "jwt malformed") {
          dangerMessage("You need to login");
        } else {
          // dangerMessage("Network issue please try again");
        }
      }
    })
    .catch((error) => {
      dangerMessage(`${error}``${error}`);
    });
  return result;
}

export default Post;
