import React, { useState } from "react";
import useCustomFetchQuery from "../../lib/Hooks/useCustomFecthQuery";
import { FaCheck } from "react-icons/fa";
import LoaderSubscriptions from "../../components/Loader/LoaderSubscriptions";
import { Styles } from "./Style";

const MySubscription = () => {
  const obj = JSON.parse(localStorage.getItem("authHC"));
  const token = obj?.token;
  const organizationID = obj?.data?.data?.organizationId;

  const {
    data: mySubscription,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["CompanyDetails", organizationID],
    `${process.env.REACT_APP_APIKEY}organizations/${organizationID}`,
    token,
    { enabled: !!organizationID }
  );

  if (isLoading) {
    return (
      <div className="page-content w-100 d-flex justify-content-center">
        <div style={{ width: "70%", margin: "0 auto" }}>
          <LoaderSubscriptions />
        </div>
      </div>
    );
  }
  console.log(
    "mySubscription....",
    JSON.stringify(mySubscription?.data?.package, null, 2)
  );

  return (
    <div className="page-content w-100 d-flex justify-content-center">
      <div style={Styles.container}>
        <h2 style={Styles.title}>Plan</h2>

        <div style={Styles.plansContainer}>
          {/* Current Plan */}
          <div style={{ ...Styles.planCard, ...Styles.selectedPlan }}>
            <div style={Styles.cardIcon}>
              <FaCheck size={15} color="white" />
            </div>

            <div>
              <div style={Styles.planHeader}>
                <span style={Styles.planName}>Current Plan</span>
                <div style={Styles.price}>
                  <span style={Styles.priceAmount}>
                    R{mySubscription?.data?.package?.price}
                  </span>
                  <span style={Styles.priceInterval}>/month</span>
                </div>
              </div>
              <div style={Styles.activationNotice}>Your package is active</div>
            </div>
            <button style={Styles.cancelButton}>Cancel Subscription</button>
          </div>

          {/* Contact Us */}
          <div style={{ ...Styles.planCard, ...Styles.contactUsPlan }}>
            <div>
              <div style={Styles.planHeader}>
                <span
                  style={{
                    ...Styles.planName,
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Contact Us
                </span>
              </div>
              <div style={{ ...Styles.activationNotice, ...Styles.whiteText }}>
                Want to update your subscription? Please click below.
              </div>
            </div>
            <div style={Styles.buttonContainer}>
              <button style={Styles.upgradeButton}>Upgrade</button>
            </div>
          </div>
        </div>

        <p style={Styles.toggleDescription}>
          This option, if checked, will renew your subscription automatically
          when the current plan expires.
        </p>
      </div>
    </div>
  );
};

export default MySubscription;
