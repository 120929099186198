import React, { useState, useEffect } from "react";
import ReactStars from "react-stars";

const CompleteSummary = ({ answers, totalScore }) => {
  const [sums, setSums] = useState({
    qualityOfWork: 0,
    workHabits: 0,
    jobKnowledge: 0,
    interpersonalSkills: 0,
    leadershipSkills: 0,
  });

  // Helper function to sum the array, handling null values as zeros
  const sumArray = (arr) => {
    return arr?.reduce((acc, current) => acc + (current || 0), 0) || 0;
  };

  // Effect to calculate sums when answers change
  useEffect(() => {
    setSums({
      qualityOfWork: sumArray(answers?.qualityOfWork),
      workHabits: sumArray(answers?.workHabits),
      jobKnowledge: sumArray(answers?.jobKnowledge),
      interpersonalSkills: sumArray(answers?.interpersonalSkills),
      leadershipSkills: sumArray(answers?.leadershipSkills),
    });
  }, [answers]);

  return (
    <>
      <div className="evaluation-view-form-left-card-container">
        <div className="evaluation-view-left-card-summary">
          <h3>Summary</h3>
          <div className="scoring-summary-title">
            <div className="w-100 d-flex justify-content-start">
              TOTAL SCORING
            </div>
            <div className="w-100 d-flex justify-content-end">
              <span>{totalScore === 18 ? 0 : totalScore}</span>
              <span className="mx-1">/</span>
              <span>100</span>
            </div>
          </div>
          <div className="mt-2 scoring-summary-subTitle">
            <div className="w-100  d-flex justify-content-start">
              FUNCTIONAL SKILLS
            </div>
            <div className="w-100 d-flex justify-content-end">
              <span>{sums?.functionalSkills}</span>
              <span className="mx-1">/</span>
              <span>50</span>
            </div>
          </div>
          <div style={{ paddingLeft: 6 }}>
            <div className="w-100 d-flex justify-content-between">
              <div className="w-100 d-flex justify-content-start">
                Quality of Work
              </div>
              <div className="w-100 d-flex justify-content-end">
                <span>{sums?.qualityOfWork}</span>
                <span className="mx-1">/</span>
                <span>15</span>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between">
              <div className="w-100 d-flex justify-content-start">
                Work Habits
              </div>
              <div className="w-100 d-flex justify-content-end">
                <span>{sums?.workHabits}</span>
                <span className="mx-1">/</span>
                <span>20</span>
              </div>
            </div>
            <div className="w-100 d-flex justify-content-between">
              <div className="w-100 d-flex justify-content-start">
                Job Knowledge
              </div>
              <div className="w-100 d-flex justify-content-end">
                <span>{sums?.jobKnowledge}</span>
                <span className="mx-1">/</span>
                <span>15</span>
              </div>
            </div>
          </div>
          <div className="mt-2 scoring-summary-subTitle">
            <div className="w-100  d-flex justify-content-start">
              INTERPERSONAL SKILLS
            </div>
            <div className="w-100 d-flex justify-content-end">
              <span>{sums?.interpersonalSkills}</span>
              <span className="mx-1">/</span>
              <span>25</span>
            </div>
          </div>
          <div className="mt-2 scoring-summary-subTitle">
            <div className="w-100  d-flex justify-content-start">
              LEADERSHIP SKILLS
            </div>
            <div className="w-100 d-flex justify-content-end">
              <span>{sums?.leadershipSkills}</span>
              <span className="mx-1">/</span>
              <span>25</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompleteSummary;
