import React from "react";
import useCustomFetchQuery from "../../../lib/Hooks/useCustomFecthQuery";
import EmployeeDepartmentTable from "../../../components/Pages/Dashboard/Manager/EmployeeDepartmentTable";
import MonthlyDepartmentAnalysisis from "../../../components/Pages/Dashboard/Manager/MonthlyDepartmentAnalysis";

const Manager = ({
  Navigate,
  userFullName,
  organizationID,
  departmentID,
  token,
}) => {
  const {
    data: myDeptOverall,
    error,
    isLoading,
  } = useCustomFetchQuery(
    ["DeptAnalytics", departmentID],
    `${process.env.REACT_APP_APIKEY}reports/${departmentID}/department`,
    token,
    { enabled: !!departmentID }
  );
  return (
    <React.Fragment>
      <EmployeeDepartmentTable
        Navigate={Navigate}
        userFullName={userFullName}
        organizationID={organizationID}
        departmentID={departmentID}
        token={token}
      />
      <MonthlyDepartmentAnalysisis myDeptOverall={myDeptOverall} />
    </React.Fragment>
  );
};

export default Manager;
