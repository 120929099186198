export const sectorList = [
  "Agriculture, forestry,phishing",
  "Business to Business",
  "Cars and mortocycles",
  "Communities",
  "Computer Hardware",
  "Computer Service",
  "Design",
  "Education",
  "Electronics and Appliances",
  "Financial Services",
  "Government",
  "Health and Personal care",
  "Logistics and delivery",
  "Mining",
  "Online services",
  "Real Estate",
  "Retail",
  "Services",
  "Travel",
  "Utilities",
  "Personal",
];
